import {React,useState} from "react";
import { AppBar, IconButton,Typography ,Toolbar,Button,makeStyles, Drawer, useTheme, Divider} from "@material-ui/core";
import {Menu as MenuIcon, TimelineOutlined} from '@material-ui/icons'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from "react-router-dom";
export default function HeaderMenu(){
  const [drOpen,setDrOpen]=useState(false)
  const drawerOpen = () =>{
    setDrOpen(true)
  }
  const drawerClose=()=>{
    setDrOpen(false)
  }
  const styles=makeStyles((theme)=>({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: 240,
      flexShrink: 0,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    titleTxt:{
      paddingRight:'15px',
      textAlign:'right'
    }
}))
const classes=styles();
const theme=useTheme();
    return(
      <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={drawerOpen} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            IOT Service
          </Typography>
          <Button color="inherit">Logged IN</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          
          <IconButton onClick={drawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Typography className={classes.titleTxt}variant="h5"> IOT Services </Typography>
        <Typography style={{padding:'15px',textAlign:'right'}}variant="subtitle1">by Core Technologies</Typography>
        <Divider/>
        <Link style={{textDecoration:'none',color:'#FFF'}}to="/dashboard" onClick={drawerClose}>
        <Toolbar>
          <IconButton><TimelineOutlined/></IconButton>
          <Typography variant="h6"noWrap>Device Dashboard</Typography>
        </Toolbar>
        </Link>
      </Drawer>
    </div>
    )
}