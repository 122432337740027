import React, { useState } from 'react';
import '../App.css'
import { TextField,makeStyles, Button, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';


async function loginUser(credentials){
    var url="https://core-technologies.com/stocks/functions/control.php?action=login&id="+credentials.username+"&pw="+credentials.password
    //console.log(credentials.username)
    return fetch(url, {
        method: 'GET',
        //body: JSON.stringify(credentials)
      })
        .then(data => data.json())
}
export default function Login({setToken}) {
    const [username,setUsername]=useState()
    const [password,setPassword]=useState()

    const handleSubmit=async e=>{
        e.preventDefault();
        //setPassword(MD5(password))
        //console.log(password)
        const token = await loginUser({
        username,
        password
        });
        setToken(token);
    }
    const styles=makeStyles((theme)=>({
        root:{
            '& > *': {
            margin:theme.spacing(1.5,'auto'),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth:500,
            
              },
           
        },
        center:{
            alignItems:'center',
            justifyContent:'center',
            margin:'auto',
        }
    }))
    const classes=styles();
  return(
      <div className="form">
          <Typography variant="h5">Core Technologies</Typography>
          <Typography variant="subtitle1">IOT Services</Typography>
    <form className={classes.root} onSubmit={handleSubmit}>
        <TextField type="text" label="User ID" variant="outlined" onChange={e=>setUsername(e.target.value)}/>
        <TextField type="password" label="Password" variant="outlined" onChange={e=>setPassword(e.target.value)}/>
        <Button className={classes.center} variant="contained" color="primary" type="submit">Log IN</Button>
    </form>
    </div>
  )
}
Login.setpropTypes={
    setToken:PropTypes.func.isRequired
}