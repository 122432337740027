import { MenuItem, Select, Snackbar,makeStyles,InputLabel,FormControl, Button, Divider, Typography, CircularProgress } from '@material-ui/core'
import React,{useState,useEffect} from 'react'
import '../App.css'

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width:240,
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    inline:{
        alignItems:'center',
        verticalAlign:'middle',
        margin:'12px'
    }
  }));
export default function Dashboard(){
    const classes = useStyles();
    useEffect(()=>{
        if(loadOnce==0){
            getDeviceSerials()
            setLoadOnce(1)
        }
       
    })
    const [deviceid,setDeviceid]=useState('')
    const [loadOnce,setLoadOnce]=useState(0)

    const [msgOpen,setMsgOpen]=useState(false)
    const [msg,setMsg]=useState('')
    const [deviceSerial,setDeviceSerial]=useState(
        {
            data:'',
            loading:true
        }
    )
    const [deviceData,setDeviceData]=useState({
        data:'',
        loading:false
    })
    const handleChange=(e)=>{
        setDeviceid(e.target.value)
    }
    const getData=async()=>{
       
        if(deviceid!=''){
            setDeviceData({
                loading:true,
                data:''
            })
            const res=await fetch("https://coretechiot.herokuapp.com/modem/serial/"+deviceid)
            .then(res=>res.json())
            .then(res=>setDeviceData({
                data:res,
                loading:false
            })
            ).catch(function(err){
                setDeviceData({
                    loading:false,
                    data:''
                })
                console.log(err)
                setMsg('Error: '+err)
                setMsgOpen(true)
            })
        }else{
            setMsg('Choose Device Serial !')
                setMsgOpen(true)
        }
    }
    const getDeviceSerials = async()=>{
       
        const res=await fetch("https://coretechiot.herokuapp.com/modem/serials/")
        .then(res=>res.json())
        .then(res=>{setDeviceSerial({
            data:res,
            loading:false
        })
        setMsg("Choose Device Serial")
        setMsgOpen(true)
        })
        .catch(function(error){
            setDeviceSerial({
                loading:false
            })
            setMsg('Error : '+error)
            setMsgOpen(true)
        })
    }
    return(
        <div className="mar">
        <h2>Dashboard</h2>
        {!deviceSerial.loading?(
            <span >
             <FormControl variant="filled" className={classes.formControl}>
             <InputLabel id="demo-simple-select-filled-label">Serial</InputLabel>
             <Select
               labelId="demo-simple-select-filled-label"
               id="demo-simple-select-filled"
               value={deviceid}
               onChange={handleChange}
             >
               <MenuItem value="">
                 <em>None</em>
               </MenuItem>
               {deviceSerial.data.map((serials)=>(
                   <MenuItem key={serials._id} value={serials.deviceid}>{serials.deviceid}</MenuItem>
               ))}
             </Select>
             
           </FormControl>
           <Button onClick={getData} className={classes.inline} color="secondary"variant="contained">Get Data</Button></span>
        ):(
        <div>Fetching from Server / Server Error
            <br/><CircularProgress/>
        </div>
        )}

        {deviceData.loading?<CircularProgress/>:(
            <div className={classes.inline}>

            <Typography style={{margin:'10px'}} variant="subtitle2">Modem Settings</Typography>
            <Typography variant="subtitle1"><em>Read Pointer</em> - {deviceData.data?deviceData.data[0].readp:''}</Typography>
            <Typography variant="subtitle1"><em>Write Pointer</em> - {deviceData.data?deviceData.data[0].writep:''}</Typography>
            <Typography variant="subtitle1"><em>RSSI</em> - {deviceData.data?deviceData.data[0].rssi:''}</Typography>
            <Typography variant="subtitle1"><em>SMS Enable</em> - {deviceData.data?deviceData.data[0].settings.smsenable:''}</Typography>
            <Typography variant="subtitle1"><em>Data Enable</em> - {deviceData.data?deviceData.data[0].settings.dataenable:''}</Typography>
            <Typography variant="subtitle1"><em>Interval</em> - {deviceData.data?deviceData.data[0].settings.interval:''}</Typography>
            <Typography variant="subtitle1"><em>No of Active Phones</em> - {deviceData.data?deviceData.data[0].settings.activeph:''}</Typography>
            <Divider style={{margin:'10px'}}/>
            <Typography style={{margin:'10px'}} variant="subtitle2">SMS Numbers</Typography>
            <Typography variant="subtitle1"><em>Number 1</em> - {deviceData.data?deviceData.data[0].phno[1]:''}</Typography>
            <Typography variant="subtitle1"><em>Number 2</em> - {deviceData.data?deviceData.data[0].phno[2]:''}</Typography>
            <Typography variant="subtitle1"><em>Number 3</em> - {deviceData.data?deviceData.data[0].phno[3]:''}</Typography>
            <Typography variant="subtitle1"><em>Number 4</em> - {deviceData.data?deviceData.data[0].phno[4]:''}</Typography>
            <Typography variant="subtitle1"><em>Number 5</em> - {deviceData.data?deviceData.data[0].phno[5]:''}</Typography>
            <Divider style={{margin:'10px'}}/>
            
            <Typography style={{margin:'10px'}} variant="subtitle2">Modem Details</Typography>
            <Typography variant="subtitle1"><em>IMEI</em> - {deviceData.data?deviceData.data[0].imei:''}</Typography>
            <Typography variant="subtitle1"><em>Sim No</em> - {deviceData.data?deviceData.data[0].simno:''}</Typography>
            <Typography variant="subtitle1"><em>Modem Version</em> - {deviceData.data?deviceData.data[0].modemver:''}</Typography>
            <Typography variant="subtitle1"><em>Power On Boot Time</em> - {deviceData.data?deviceData.data[0].pontime:''}</Typography>
            <Typography variant="subtitle1"><em>Device Time</em> - {deviceData.data?deviceData.data[0].devicetime:''}</Typography>
            <Typography variant="subtitle1"><em>Model No</em> - {deviceData.data?deviceData.data[0].modelno:''}</Typography>
            <Typography variant="subtitle1"><em>Network Used</em> - {deviceData.data?deviceData.data[0].carrier:''}</Typography>
            <Typography variant="subtitle1"><em>SW Ver</em> - {deviceData.data?deviceData.data[0].versionno:''}</Typography>
            <Divider style={{margin:'10px'}}/>
            <Divider style={{margin:'10px'}}/>
            <center>Data updated on server - {deviceData.data?deviceData.data[0].updated:''}</center>
            </div>
        )}
        <Snackbar
        autoHideDuration={3000}
        message={msg}
        open={msgOpen}
        onClose={()=>setMsgOpen(false)}
        />
        </div>
    )
}