import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Preferences from "./components/Preferences";
import HeaderMenu from "./components/pageAppBar";
import useToken from "./components/useToken";
import {createTheme,CssBaseline,ThemeProvider} from '@material-ui/core'


function App() {
  const [toggleDark,setToggleDark]=useState(false)
  const { token, setToken } = useToken();

  const myTheme=createTheme({
    palette:{
      type:"dark",
      primary:{
        main:"#ac0800"
      },
      secondary:{
        main:"#005b9f"
      }
    }
  })
  if(!token){
    return <Login setToken={setToken} />
  }

  return (
    <ThemeProvider theme={myTheme}>
      <CssBaseline/>
      <div className="wrapper">

            <BrowserRouter>
            <HeaderMenu/>
              <Switch>
                <Route exact path="/">
                  <div class="mar">Welcome !<br/>Open Menu - Device DashBoard</div>
                  </Route>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/preferences">
                  <Preferences />
                </Route>
              </Switch>
            </BrowserRouter>
        </div>
    </ThemeProvider>
  );
}

export default App;
